import { AuthProfileOutput, CMSRegionTeam, JWTToken, OrderPaymentInfo } from '@grandstand/presentation-models'
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { getDeviceType, type DeviceInfo } from '../../hooks/useDeviceInfo'
import { AuthDeviceError, ClientError, ConfigError, NetworkError, ServerError } from '../../type/Error'
import { ConfigServiceContext, useContextUnconditionally, type ConfigService } from '../config/ConfigService'
import { type MVPDListOutput } from './MVPD'

import { updateUserTokens, useValidUser } from '../../hooks/useValidUser'

import { useRouter } from 'next/router'
import { Couchrights, CouchrightsWarning, useCouchrights } from '../../hooks/useCouchrights'
import { ApiClient } from '../../newPackages/ApiClient'
import { CurrentUser } from '../../newPackages/StorageProviders/currentUserStore'
import { Logger } from '../../utils/logger'
import { createTealiumService } from '../tealium/TealiumService'
import { TealiumService } from '../tealium/types'

// response type for /region{?/zip_code} endpoint
// see https://bitbucket.org/ballysports-apps/middleware/src/main/services/middleware/src/services/_region/region.ts
export interface DeviceRegion {
  in_market: boolean
  zip_code: string
  available_networks: AvailableNetwork[]
  teams: CMSRegionTeam[]
  city?: string
  state?: string
}

export interface AvailableNetwork {
  id: string
  local_network_ids?: string[]
}

export interface RegisterResponse {
  user_token: string
  refresh_token: string
  code: string
  status: string
  message: string
}

/**
 * A type that represents the response from the getDeviceAuth method of the UserService.
 */
export interface AuthDevicesResponse {
  /**
   * A code that the user needs to enter on the `login_url` to start the authentication process.
   */
  code: string
  /**
   * The polling interval in seconds that the pollForDeviceAuth method should use to check the status of the authentication.
   */
  polling_interval: number
  /**
   * The URL that the user needs to visit on their device to enter the code and authenticate.
   * For QR code
   */
  login_url: string
  /**
   * The URL that the user needs to visit on their device to enter the code and authenticate.
   * For display
   */
  display_url: string
  /**
   * The URL that the pollForDeviceAuth method should use to check the status of the authentication.
   */
  status_url: string
  /**
   * Time before the current code expires and is no longer usable
   */
  expires_in: number
}

export type ManualRefreshType = 'logInMVPD' | 'none'

/**
 * A type that represents the response from the `getDevices` method of the UserService.
 */
export interface DeviceResponse {
  /**
   * An object containing information about the user.
   */
  user: {
    /**
     * The unique identifier for the user.
     */
    id: string
    /**
     * The name of the user.
     */
    name: string
    /**
     * The email address of the user.
     */
    email: string
  }
  /**
   * The unique identifier for the device.
   */
  id: string
  /**
   * The unique identifier for the device assigned by the client.
   */
  clientDeviceId: string
  /**
   * The timestamp of the last stream activity on this device. It could be null if there is no recent activity.
   */
  lastStreamActivity: string | null
  /**
   * The unique identifier for the device assigned by the MVPD.
   */
  mvpdDeviceId: string
  /**
   * The name of the device.
   */
  name: string
  /**
   * The name of the MVPD associated with this device.
   */
  mvpdName: string
  /**
   * A flag indicating whether the device is currently in use.
   */
  is_current: boolean
}

/**
 * A service that provides methods and properties related to the current user and device authentication.
 */
export interface UserService {
  /**
   * The current user object, whether logged in or out. Only undefined in cases of _serious_ errors
   */
  currentUser?: AuthProfileOutput
  /**
   * A boolean property that indicates whether the user is logged in or not.
   */
  isLoggedIn: boolean
  /**
   * A boolean property that indicates whether the user is in a valid bally sports market.
   */
  isInMarket: boolean
  isDTC: boolean

  getDevices: () => Promise<DeviceResponse[]>
  /**
   * Login with username + password
   *
   * A method that returns a promise that resolves with an AuthDevicesResponse object,
   * which contains the code needed to log in, a login url, and a status url which is polled
   * to determine whether the user has logged in or not.
   */
  getDeviceAuth: (deviceInfo: DeviceInfo) => Promise<AuthDevicesResponse>
  /**
   * Login with username + password
   *
   * A method that returns a promise that resolves with an AuthDevicesResponse object,
   * which contains the code needed to log in, a login url, and a status url which is polled
   * to determine whether the user has logged in or not.
   */
  getDeviceAuthForMultiStage: (deviceInfo: DeviceInfo, flow: 'mvpd' | 'dtc') => Promise<AuthDevicesResponse>
  /**
   * Login with MVPD
   *
   * A method that returns a promise that resolves with an AuthDevicesResponse object,
   * which contains the code needed to log in, a login url, and a status url which is polled
   * to determine whether the user has logged in or not.
   *
   * Note: may be multi-auth or not depending if the user is currently authenticated
   */
  getMVPDAuth: (deviceInfo: DeviceInfo) => Promise<AuthDevicesResponse>
  /**
   *
   */
  updateTos: (tos_version: string) => Promise<boolean>
  /**
   *
   */
  updateZipcode: (zipCode: string) => Promise<boolean>
  /**
   *
   */
  updateName: (name: string) => Promise<boolean>
  /**
   * Force a refresh of the user's token.
   *
   * @returns `true` if the user's token was refreshed, `false` otherwise
   */
  refresh: () => Promise<boolean>
  /**
   * Refresh a user's token given a userToken and a refreshToken
   *
   * @returns `true` if the user's token was refreshed, `false` otherwise
   */
  manualRefresh: (
    userToken: JWTToken,
    refreshToken: JWTToken,
    manualRefreshType?: ManualRefreshType // for tealium analytics
  ) => Promise<boolean>
  /**
   * A method that takes an AuthDevicesResponse object as a parameter and returns a NodeJS.Timer object,
   * which periodically checks if the device authentication has been completed or canceled.
   * N.B. make sure to cancel the timer returned or you'll regret it!
   */
  pollForDeviceAuth: (
    authDevices: AuthDevicesResponse,
    onError: (error: Error) => void,
    onSucess?: () => void
  ) => NodeJS.Timeout
  /**
   * Logs in a user with the given credentials
   * @param {string} email - The email of the user.
   * @param {string} password - The password of the user.
   * @param {string} deviceId - The device ID of the user.
   * @param {object} device_info - Object with device_id and device_name.
   * @returns {Promise<boolean>} A promise that resolves to a `true` object if the login is successful, `false` otherwise, or rejects with an error
   */
  logIn: (
    email: string,
    password: string,
    tos_version: string,
    device_info: DeviceInfo,
    device_code?: string
  ) => Promise<boolean>
  /**
   * Registers a user with the given credentials
   * @param {string} email - The email of the user.
   * @param {string} password - The password of the user.
   * @param {string} deviceId - The device ID of the user.
   * @param {object} device_info - Object with device_id and device_name.
   * @param {string} zipCode - The zip code of the user.
   * @param {string} name - The user's name
   * @param {string} tos_version - The tos version the user has accepted
   * @returns {Promise<boolean>} A promise that resolves to a `true` object if the registration is successful, `false` otherwise, or rejects with an error
   */
  register: (
    email: string,
    password: string,
    name: string,
    zipCode: string,
    tos_version: string,
    device_info: DeviceInfo,
    device_code?: string
  ) => Promise<RegisterResponse>
  /**
   * Log out the currently logged in user, if any
   */
  logOut: () => Promise<void>
  /**
   * Log out the current MVPD device, if any
   */
  logOutMVPD: () => void
  /**
   * Reset password
   */
  resetPassword: (email: string) => Promise<boolean>
  /**
   * Update password
   */
  updatePassword: (password: string, userToken: string) => Promise<boolean>
  /**
   * Get MVPD list
   */
  mvpdList: () => Promise<MVPDListOutput>
  /**
   * DTC Entrypoint
   */
  dtcEntrypoint: (deviceInfo: DeviceInfo) => Promise<AuthDevicesResponse>
  /**
   * Add the user's Favorites team lists.
   * @param team_id - team id to be added
   * @returns true if the item is added successfully
   */
  addFavorite: (team_id: string) => Promise<string[]>
  /**
   * @param team_id - team id to be removed
   * @returns string[] - updated favorites.teams (empty array if !currentUser)
   */
  removeFavorite: (team_id: string) => Promise<string[]>
  /**
   * Update the user's Favorites team lists.
   * @param {object} favorites - Object with teams array.
   * @returns string[] - updated favorites.teams (empty array if !currentUser)
   */
  updateFavorites: (teams: string[]) => Promise<boolean>
  /**
   *
   * @param id - current Device id. All devices will be removed except this id
   * @returns true if all device is removed successfully
   */
  removeAllOtherDevices: (device_id: string) => Promise<DeviceResponse[]>
  /**
   * Begins the account deletion process for the current user.
   *
   * @return {Promise<boolean>} - A promise that resolves to `true` if the deletion process was successfully initiated, or rejects with an error if initiation fails.
   */
  startDeleteAccount: () => Promise<boolean>
  /**
   * Verifies the account deletion process for the current user.
   *
   * @return {Promise<boolean>} - A promise that resolves to `true` if the deletion process was verified successfully, or rejects with an error if verification fails.
   */
  verifyDeleteAccount: (code: string) => Promise<boolean>
  /**
   * Verifies the account deletion process for the current user.
   *
   * @return {Promise<boolean>} - A promise that resolves to `true` if the deletion process was verified successfully, or rejects with an error if verification fails.
   */

  finalizeDeleteAccount: () => Promise<boolean>
  isDTCEntitled: () => boolean
  getPayments: () => Promise<OrderPaymentInfo>
  isMVPD: boolean
  freeTrialStatus: boolean
  getFreeTrialStatus: () => Promise<boolean>
  deviceRegion?: DeviceRegion
  refreshDeviceRegion: () => Promise<boolean>
  couchrights: Couchrights | null
  couchrightsWarning: CouchrightsWarning | null
  checkin: () => void
  dismissCouchrightsWarning: () => void
  isInFavorites: (teamId: string) => boolean
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const UserServiceContext = createContext<UserService>({
  isLoggedIn: false,
  getDevices: async function (): Promise<DeviceResponse[]> {
    throw new Error('Function not implemented.')
  },
  getDeviceAuth: (deviceInfo: DeviceInfo) => {
    throw new Error('Function not implemented.')
  },
  getDeviceAuthForMultiStage: (deviceInfo: DeviceInfo, flow: 'mvpd' | 'dtc') => {
    throw new Error('Function not implemented.')
  },
  pollForDeviceAuth: function (_deviceAuth: AuthDevicesResponse): NodeJS.Timeout {
    throw new Error('Function not implemented.')
  },
  logOut: function (): Promise<void> {
    throw new Error('Function not implemented.')
  },
  logOutMVPD: function (): void {
    throw new Error('Function not implemented.')
  },
  logIn: async function (
    _email: string,
    _password: string,
    _tos_version: string,
    _device_info: DeviceInfo,
    _device_code?: string
  ): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  register: async function (
    _email: string,
    _password: string,
    _name: string,
    _zipCode: string,
    tos_version: string,
    _device_info: DeviceInfo,
    _device_code?: string
  ): Promise<RegisterResponse> {
    throw new Error('Function not implemented.')
  },
  resetPassword: async function (_email: string): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  updatePassword: async function (password: string, userToken: string): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  mvpdList: async function (): Promise<MVPDListOutput> {
    throw new Error('Function not implemented.')
  },
  refresh: async function (): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  manualRefresh: async function (
    userToken: JWTToken,
    refreshToken: JWTToken,
    manualRefreshType?: ManualRefreshType
  ): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  dtcEntrypoint: async function (deviceInfo: DeviceInfo): Promise<AuthDevicesResponse> {
    throw new Error('Function not implemented.')
  },
  getMVPDAuth: async function (deviceInfo: DeviceInfo): Promise<AuthDevicesResponse> {
    throw new Error('Function not implemented.')
  },
  updateTos: async function (tos_version: string): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  updateZipcode: async function (zipCode: string): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  updateName: async function (zipCode: string): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  addFavorite: async function (team_id: string): Promise<string[]> {
    throw new Error('Function not implemented.')
  },
  removeFavorite: async function (team_id: string): Promise<string[]> {
    throw new Error('Function not implemented.')
  },
  updateFavorites: async function (_teams: string[]): Promise<boolean> {
    throw new Error('Function not implemented.')
  },
  removeAllOtherDevices: async function (device_id: string): Promise<DeviceResponse[]> {
    throw new Error('Function not implemented.')
  },
  startDeleteAccount: async (): Promise<boolean> => {
    throw new Error('Function not implemented.')
  },
  verifyDeleteAccount: async (code: string): Promise<boolean> => {
    throw new Error('Function not implemented.')
  },
  finalizeDeleteAccount: async (): Promise<boolean> => {
    throw new Error('Function not implemented.')
  },
  isInMarket: false,
  isDTCEntitled: (): boolean => {
    throw new Error('Function not implemented.')
  },
  getPayments: async (): Promise<OrderPaymentInfo> => {
    throw new Error('Function not implemented.')
  },
  isDTC: false,
  isMVPD: false,
  freeTrialStatus: false,
  getFreeTrialStatus: async (): Promise<boolean> => false,
  refreshDeviceRegion: async (): Promise<boolean> => false,
  isInFavorites: (teamId: string) => false,
  couchrights: null,
  couchrightsWarning: null,
  checkin: () => {},
  dismissCouchrightsWarning: () => {},
})

/* eslint-enable @typescript-eslint/no-unused-vars */

// Just proposal at this point maybe we need to move this to somewhere else
export const callAPI = async (
  endpoint: string | undefined,
  method: 'PUT' | 'PATCH' | 'DELETE' | 'POST' | 'GET',
  body: any = undefined,
  token: string = ''
) => {
  const noBodyAllowedMethods = ['GET']
  if (endpoint === undefined) {
    throw new ConfigError('Config not loaded!')
  }

  const options = {
    url: endpoint,
    method,
    body: noBodyAllowedMethods.includes(method) ? undefined : body,
  }

  try {
    let response = null
    response = await ApiClient.convenientApiFetch({
      ...options,
    })
    if (!response.ok) {
      const data = await response.json()
      if (response.status >= 500) {
        throw new ServerError(data.message, response.status)
      } else if (response.status >= 400) {
        throw new ClientError(data.message, response.status)
      }
    }
    return await response.json()
  } catch (e) {
    if (e instanceof Error) {
      if (e instanceof ServerError || e instanceof ClientError) {
        throw e
      } else {
        if (e.name === 'TypeError' && e.message === 'Failed to fetch') {
          throw new NetworkError('Failed to fetch')
        }
        throw e
      }
    }
  }
}

export interface CreateUserServiceProps {
  currentUser: AuthProfileOutput | undefined
  setCurrentUser: (value: CurrentUser) => void
  isLoggedIn: boolean
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  isInMarket: boolean
  setIsInMarket: React.Dispatch<React.SetStateAction<boolean>>
  isDTC: boolean
  isMVPD: boolean
  freeTrialStatus: boolean
  setFreeTrialStatus: React.Dispatch<React.SetStateAction<boolean>>
  config: ConfigService
  deviceRegion: DeviceRegion | undefined
  setDeviceRegion: React.Dispatch<React.SetStateAction<DeviceRegion | undefined>>
  couchrights: Couchrights | null
  couchrightsWarning: CouchrightsWarning | null
  checkin: () => void
  dismissCouchrightsWarning: () => void
  tealium: TealiumService
}
export const createUserService = ({
  currentUser,
  setCurrentUser,
  isLoggedIn,
  setIsLoggedIn,
  isInMarket,
  isDTC,
  isMVPD,
  freeTrialStatus,
  setFreeTrialStatus,
  config,
  deviceRegion,
  setDeviceRegion,
  couchrights,
  couchrightsWarning,
  checkin,
  dismissCouchrightsWarning,
  tealium,
}: CreateUserServiceProps): UserService => {
  const userService: UserService = {
    currentUser,
    isLoggedIn,
    isInMarket,
    isDTC,
    isMVPD,
    freeTrialStatus,
    couchrights,
    couchrightsWarning,
    checkin,
    dismissCouchrightsWarning,
    getDevices: async (): Promise<DeviceResponse[]> => {
      return await callAPI(
        config.currentConfig.API.services.auth_services.devices.list,
        'GET',
        {},
        currentUser?.user_token ?? ''
      )
    },
    getDeviceAuth: async (deviceInfo: DeviceInfo): Promise<AuthDevicesResponse> => {
      return await callAPI(config.currentConfig.API.services.auth_services.ballys.auth_devices, 'POST', {
        ...deviceInfo,
      })
    },
    getDeviceAuthForMultiStage: async (deviceInfo: DeviceInfo, flow: 'mvpd' | 'dtc'): Promise<AuthDevicesResponse> => {
      const url = config.currentConfig.API.services.auth_services.multi_stage_auth.auth_devices
      if (url === undefined) {
        throw new Error('Config not loaded!')
      }
      const results = await ApiClient.convenientApiFetch({
        url,
        method: 'POST',
        body: {
          device_info: deviceInfo,
          flow,
        },
      })
      return await results.json()
    },
    pollForDeviceAuth: function (
      authDevices: AuthDevicesResponse,
      onError: (error: Error) => void,
      onSuccess?: () => void
    ): NodeJS.Timeout {
      const logger = Logger.of('UserService.pollForDeviceAuth')
      const interval = setInterval(async () => {
        logger.info('Polling for device auth')
        const url = authDevices.status_url
        if (!url) {
          if (onError) {
            onError(new AuthDeviceError('Unable to get the URL for polling')) // pass error to onError callback
          }
          return // stop execution
        }
        try {
          const user = await callAPI(url, 'GET', undefined, currentUser?.user_token)
          if (user.profile?.internal === undefined) {
            return
          }
          setCurrentUser(user as AuthProfileOutput)
          setIsLoggedIn(true)
          clearInterval(interval)

          if (onSuccess) {
            onSuccess()
          }
        } catch (error) {
          logger.error('Auth device failed', { error })
          if (onError) {
            onError(error as Error) // pass error to onError callback
          }
        }
      }, authDevices.polling_interval)

      return interval
    },
    logOut: async function (): Promise<void> {
      try {
        await ApiClient.logout()
        tealium.logOut()
        setIsLoggedIn(false)
      } catch (error) {
        // If we failed to log out, just do nothing
        console.error('Error logging out', error)
      }
    },
    logOutMVPD: async function (): Promise<void> {
      try {
        const userProfile = await callAPI(
          config.currentConfig.API.services.auth_services.mvpd.logout,
          'DELETE',
          undefined,
          currentUser?.user_token
        )
      } catch (error) {
        // If we failed to log out, just do nothing
        console.error('Error logging out', error)
      }
    },
    logIn: async function (
      email: string,
      password: string,
      tos_version: string,
      device_info: DeviceInfo,
      device_code?: string
    ): Promise<boolean> {
      const data = await callAPI(config.currentConfig.API.services.auth_services.ballys.login, 'POST', {
        email,
        password,
        tos_version: tos_version || '',
        device_id: device_info.device_id,
        device_info: {
          device_code,
          ...device_info,
        },
        device_code,
      })
      if (data.profile?.internal === undefined) {
        return false
      }
      const user = data as AuthProfileOutput
      setCurrentUser(user)

      // tealium analytics
      tealium.logIn({
        user_id: user.analytics.default?.user_id ?? user.analytics.default?.auth_userid,
      })
      return true
    },
    register: async function (
      email: string,
      password: string,
      name: string,
      zipCode: string,
      tos_version: string,
      device_info: DeviceInfo,
      device_code?: string
    ): Promise<RegisterResponse> {
      const url = config.currentConfig.API.services.auth_services.ballys.register
      if (url === undefined) {
        throw new Error('Config not loaded!')
      }

      const response = await ApiClient.convenientApiFetch({
        url,
        method: 'POST',
        body: {
          email,
          password,
          device_id: device_info.device_id,
          device_info: { device_code, ...device_info },
          name,
          zip_code: zipCode,
          tos_version: tos_version ?? '',
          device_code,
        },
      })

      const data = await response.json()
      if (data.profile?.internal === undefined) {
        return data as RegisterResponse
      }
      const user = data as AuthProfileOutput
      setCurrentUser(user)
      setIsLoggedIn(true)

      // tealium analytics
      tealium.createAccount({
        user_id: user.analytics.default?.user_id ?? user.analytics.default?.auth_userid,
        email,
        name,
        user_zip: zipCode,
        marketing_opt_in: false,
      })
      return data as RegisterResponse
    },
    resetPassword: async function (email: string): Promise<boolean> {
      const url = config.currentConfig.API.services.auth_services.ballys.forgot_password
      if (url === undefined) {
        throw new Error('Config not loaded!')
      }

      const response = await ApiClient.convenientApiFetch({
        url,
        method: 'POST',
        body: {
          email,
        },
      })

      const result = await response.json()
      return result.success
    },
    updatePassword: async function (password: string, userToken: string): Promise<boolean> {
      const url = config.currentConfig.API.services.auth_services.ballys.reset_password
      if (url === undefined) {
        throw new Error('Config not loaded!')
      }

      const response = await ApiClient.convenientApiFetch({
        url,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
        body: {
          password,
        },
      })

      const result = await response.json()
      return result.success
    },

    mvpdList: async function (): Promise<MVPDListOutput> {
      const url = config.currentConfig.API.services.auth_services.mvpd.list
      if (url === undefined) {
        throw new Error('Config not loaded!')
      }

      const response = await ApiClient.convenientApiFetch({
        url,
        method: 'GET',
      })

      return await response.json()
    },
    refresh: async function (): Promise<boolean> {
      Logger.of('UserService.refresh').debug('start', {
        logCategory: 'refresh',
      })
      try {
        const url = config.currentConfig.API.services.auth_services.ballys.profile || ''
        const response = await ApiClient.convenientApiFetch({ url, method: 'GET' })
        const profile = await response.json()
        setCurrentUser(profile)
      } catch (err) {
        Logger.of('UserService.refresh').error('failed refresh', {
          logCategory: 'refresh',
          error: err,
        })
        throw err
      }
      return true
    },
    manualRefresh: async function (
      userToken: JWTToken,
      refreshToken: JWTToken,
      manualRefreshType?: ManualRefreshType
    ): Promise<boolean> {
      Logger.of('UserService.manualRefresh').debug('start', {
        logCategory: 'refresh',
        manualRefreshType,
      })
      try {
        updateUserTokens(userToken, refreshToken)
        await ApiClient.forceRefresh()
        Logger.of('UserService.manualRefresh').debug('successful refresh', {
          logCategory: 'refresh',
          userToken,
          refreshToken,
        })
      } catch (err) {
        Logger.of('UserService.refresh').error('failed refresh', {
          logCategory: 'refresh',
          error: err,
        })
        throw err
      }
      return true
    },
    dtcEntrypoint: async function (deviceInfo: DeviceInfo): Promise<AuthDevicesResponse> {
      const url = config.currentConfig.API.services.auth_services.multi_stage_auth.auth_devices

      if (url === undefined) {
        throw new Error('Config not loaded!')
      }
      const user = currentUser
      if (user === undefined) {
        throw new Error('No user!')
      }
      const deviceType = getDeviceType()
      const results = await ApiClient.convenientApiFetch({
        url,
        method: 'POST',
        body: {
          device_info: deviceInfo,
          flow: 'dtc',
          partner_source: deviceType === 'tv_samsung' ? 'samsung' : deviceType === 'tv_xboxone' ? 'xbox' : undefined,
        },
      })
      if (!results.ok) throw results
      return await results.json()
    },
    getMVPDAuth: async function (deviceInfo: DeviceInfo): Promise<AuthDevicesResponse> {
      const url = isLoggedIn
        ? config.currentConfig.API.services.auth_services.mvpd.auth_devices
        : config.currentConfig.API.services.auth_services.multi_stage_auth.auth_devices

      if (url === undefined) {
        throw new Error('Config not loaded!')
      }
      const body = isLoggedIn
        ? deviceInfo
        : {
            device_info: deviceInfo,
            flow: 'mvpd',
          }
      const results = await ApiClient.convenientApiFetch({
        url,
        method: 'POST',
        body,
      })
      return await results.json()
    },
    updateTos: async function (tos_version: string): Promise<boolean> {
      const url = config.currentConfig.API.services.auth_services.ballys.profile
      const user = currentUser
      if (url === undefined) {
        throw new Error('Config not loaded!')
      }
      if (user === undefined) {
        throw new Error('No user!')
      }

      const body = { tos_version }
      const response = await ApiClient.convenientApiFetch({
        url,
        method: 'PATCH',
        body,
      })
      if (response.ok) {
        const newUser = await response.json()
        setCurrentUser(newUser)
        return true
      } else {
        return false
      }
    },
    updateZipcode: async function (zipCode: string): Promise<boolean> {
      if (!/^\d{5}$/.test(zipCode)) return false
      const url = config.currentConfig.API.services.auth_services.ballys.profile
      const user = currentUser
      if (url === undefined) {
        throw new Error('Config not loaded!')
      }
      if (user === undefined) {
        throw new Error('No user!')
      }

      const body = { zip_code: zipCode }
      const response = await ApiClient.convenientApiFetch({
        url,
        method: 'PATCH',
        body,
      })
      if (response.ok) {
        const newUser = await response.json()
        setCurrentUser(newUser)
        return true
      } else {
        return false
      }
    },
    updateName: async function (name: string): Promise<boolean> {
      const url = config.currentConfig.API.services.auth_services.ballys.profile
      const user = currentUser
      if (url === undefined) {
        throw new Error('Config not loaded!')
      }

      if (user === undefined) {
        throw new Error('No user!')
      }

      const body = { name }
      const response = await ApiClient.convenientApiFetch({
        url,
        method: 'PATCH',
        body,
      })
      if (response.ok) {
        const newUser = await response.json()
        setCurrentUser(newUser)
        tealium.updateName({ name })
        return true
      } else {
        return false
      }
    },
    addFavorite: async (team_id: string): Promise<string[]> => {
      if (!currentUser) {
        return []
      }
      const current: Array<string> = currentUser.profile.favorites.teams ?? []
      /**
       * Add team to favorites (without duplicate)
       */

      const teams = [...new Set([...current, team_id])]
      /**
       * Update Favorites team lists
       */
      await userService.updateFavorites(teams)

      return teams
    },
    removeFavorite: async (team_id: string): Promise<string[]> => {
      if (!currentUser) {
        return []
      }
      const current = currentUser?.profile.favorites.teams ?? []
      /**
       * Remove teams
       */
      const next = current.filter((teamId) => teamId !== team_id)
      await userService.updateFavorites(next)
      return next
    },
    updateFavorites: async (teams: string[]): Promise<boolean> => {
      const user = await callAPI(
        config.currentConfig.API.services.auth_services.ballys.favorites,
        'PUT',
        {
          favorites: { teams },
        },
        isLoggedIn ? currentUser?.user_token : ''
      )
      if (user.profile.internal === undefined) {
        return false
      }
      setCurrentUser(user as AuthProfileOutput)
      return true
    },
    removeAllOtherDevices: async function (device_id: string): Promise<DeviceResponse[]> {
      return await callAPI(
        `${config.currentConfig.API.services.auth_services.devices.delete_all}/${device_id}`,
        'DELETE',
        {},
        currentUser?.user_token ?? ''
      )
    },
    startDeleteAccount: async (): Promise<boolean> => {
      try {
        const response = await callAPI(
          config.currentConfig.API.services.auth_services.ballys.start_delete,
          'PUT',
          {},
          currentUser?.user_token ?? ''
        )
        const refreshedUser = response
        setCurrentUser(refreshedUser)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    // || deleteStatus !== 'pending_email_verify' || deleteStatus !== 'email_verified'
    verifyDeleteAccount: async (code: string): Promise<boolean> => {
      if (currentUser === undefined) {
        return false
      }
      try {
        const response = await ApiClient.convenientApiFetch({
          method: 'PUT',
          url: `${config.currentConfig.API.services.auth_services.ballys.verify_email}?code=${code}`,
        })
        if (!response.ok) {
          throw new Error('Invalid code')
        }
        const refreshedUser = await response.json()
        setCurrentUser(refreshedUser)
      } catch (e) {
        console.error('Error:', e)
        return false
      }
      return true
    },
    finalizeDeleteAccount: async (): Promise<boolean> => {
      if (currentUser === undefined || currentUser.profile.internal?.delete_status !== 'email_verified') {
        return false
      }

      try {
        const response = await callAPI(
          config.currentConfig.API.services.auth_services.ballys.profile,
          'DELETE',
          {},
          currentUser?.user_token ?? ''
        )
        setCurrentUser(response)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    isDTCEntitled: (): boolean => {
      return isDTC
    },
    getPayments: async (): Promise<OrderPaymentInfo> => {
      const token = currentUser?.user_token

      if (
        config === undefined ||
        token === undefined ||
        config.currentConfig.API.services.subscription_services.payments === undefined
      ) {
        return []
      }

      const data = await ApiClient.convenientApiFetch({
        url: config.currentConfig.API.services.subscription_services.payments,
        method: 'GET',
      })
      const response = await data.json()
      return response as OrderPaymentInfo
    },
    getFreeTrialStatus: async (): Promise<boolean> => {
      const url = config.currentConfig.API.services?.subscription_services?.free_trial_status
      const token = userService.currentUser?.user_token
      if (config === undefined || url === undefined) {
        setFreeTrialStatus(false)
        return false
      }
      if (!userService.isLoggedIn || token === undefined) {
        setFreeTrialStatus(true)
        return true
      }
      let next: boolean = false
      try {
        const res = await ApiClient.convenientApiFetch({
          url,
          method: 'GET',
        })
        const value = await res.json()
        if (res.ok) {
          next = !!value?.is_eligible
        } else {
          next = false
        }
      } catch (_) {
        next = false
      } finally {
        setFreeTrialStatus(next)
        return next
      }
    },
    deviceRegion,
    refreshDeviceRegion: async (): Promise<boolean> => {
      const url = config.currentConfig.API.services?.auth_services?.region
      if (config === undefined || url === undefined) {
        return false
      }

      try {
        const response = await callAPI(url, 'GET', undefined, currentUser?.user_token ?? '')
        setDeviceRegion(response)
        return true
      } catch (_) {
        return false
      }
    },
    isInFavorites: (teamId: string): boolean => {
      const favorites = currentUser?.profile?.favorites?.teams
      return (favorites && favorites?.length > 0 && favorites?.includes(teamId)) ?? false
    },
  }
  return userService
}

interface UserServiceProviderProps {
  app: 'web' | 'connected-web'
}

export const UserServiceProvider = (props: React.PropsWithChildren<UserServiceProviderProps>) => {
  const config = useContextUnconditionally(ConfigServiceContext)
  const [currentUser, setCurrentUser] = useValidUser()
  const [deviceRegion, setDeviceRegion] = useState<DeviceRegion | undefined>(undefined)
  const [isLoggedIn, setIsLoggedIn] = useState(currentUser?.profile.internal?.email !== undefined)
  const [isInMarket, setIsInMarket] = useState((currentUser?.profile.region.all_regional_teams.length ?? 0) > 0)
  const isDTC = useMemo<boolean>(() => {
    const entitlements = currentUser?.profile.entitlements
    return entitlements === undefined ? false : entitlements.some((entitlement) => entitlement.type === 'dtc')
  }, [currentUser])
  const isMVPD = useMemo<boolean>(() => {
    const entitlements = currentUser?.profile.entitlements
    return entitlements === undefined ? false : entitlements.some((entitlement) => entitlement.type === 'mvpd')
  }, [currentUser?.profile.entitlements])

  const [freeTrialStatus, setFreeTrialStatus] = useState<boolean>(true) // Default to true, because anonymous users should be treated as eligible and be shown all offers/promos.

  const { couchrights, couchrightsWarning, dismissCouchrightsWarning, checkin } = useCouchrights(currentUser)

  const router = useRouter()

  const tealium = createTealiumService()

  const liveService: UserService = createUserService({
    currentUser,
    setCurrentUser,
    isLoggedIn,
    setIsLoggedIn,
    isInMarket,
    setIsInMarket,
    isDTC,
    isMVPD,
    freeTrialStatus,
    setFreeTrialStatus,
    config,
    deviceRegion,
    setDeviceRegion,
    checkin,
    couchrights,
    couchrightsWarning,
    dismissCouchrightsWarning,
    tealium,
  })

  // Keep isLoggedIn up-to-date
  useEffect(() => {
    setIsLoggedIn(currentUser?.profile.internal?.email !== undefined)
  }, [currentUser?.profile.internal?.email])
  // Keep isInMarket up-to-date
  useEffect(() => {
    setIsInMarket((currentUser?.profile.region.all_regional_teams.length ?? 0) > 0)
  }, [currentUser?.profile.region.all_regional_teams.length])

  // keep free-trial-status up-to-date
  const getFreeTrialStatusCount = useRef<number>(0)
  useEffect(() => {
    const count = getFreeTrialStatusCount.current + 1
    getFreeTrialStatusCount.current = count

    const timeout = setTimeout(() => {
      try {
        liveService.getFreeTrialStatus()
      } catch (_) {
        // do nothing
      }
    }, 250)
    return () => {
      clearTimeout(timeout)
    }
  }, [liveService, isLoggedIn, isMVPD, isDTC])

  // keep device region up-to-date on mount
  useEffect(() => {
    if (deviceRegion) {
      return
    }
    liveService.refreshDeviceRegion()
  }, [liveService, deviceRegion])
  return (
    <>
      <UserServiceContext.Provider value={liveService}>{props.children}</UserServiceContext.Provider>
    </>
  )
}
